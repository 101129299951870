/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/

.dontdisplay {display:none;}
/*.hideonMobile {display: block;}*/

/*.certificate, .moreinformation{font-size: 1.35em; }*/
#hero .slideDescription { font-size: 1.2em; }
a.enrol{font-weight: 400; letter-spacing: 1.5px;}
#mainMenu .nav > li > a {font-weight: 700;}
 ul.responsive3.testimonials {font-size: 1.1em;}

 .evenBigger {font-size: 135%;}

/*************************
Colours
*************************/
.black, #mainMenu .nav > li > a {

    letter-spacing: 0.5px;
}
.white, #mainMenu .nav  li  a:hover, /*a.enrol, a.enrol:hover,*/ #mainMenu .nav  li  a { color: $white; }






/*********************
LAYOUT & GRID STYLES
*********************/
.row {padding: 1.5em 0;}
/* Reduce padding on some rows*/
#breadcrumbs .row, #CTA .row {padding: 1em 0;}

.breadWrap #breadcrumbs{
	background: transparent;

	&:before	{
			/*content: '';
			width: 85%;
			height: 60px;
			min-width: 800px;
			background: lighten($color-tertiary, 20%);
			box-shadow: 0px 2px 20px 2px rgba(0,0,0,0.3);
			position: absolute;
			left: -250px;
			top: 0;
			transform: skew(-45deg);
			z-index: -1;*/

	}
				
}
 
 /* Turn off padding on some rows*/
#header .row {padding: 0.25em 0;}

body.Home #leftcol {
	.threecol{
		width: 25%; 
		margin: 0;
		margin-left: 0;

		&.last{
			float: left;
		}
	}

	.fourcol{
		width: 33.33333%;
		margin: 0;
		margin-left: 0;
			&.last{
			float: left;
		}
			&:nth-child(3){
			width: 33.3333%;
		}
		}
}
/*********************
HEADER STYLES
*********************/


#header {
	/*.student-login {top:0; }*/
	/*.row {height:auto;}*/

	#logoHelper{
			display: none;			
			/*width: 225px;
			height: 250px;
			background: $color-primary;
			@include center(true,false);
			top: -80px;
			margin-left: -4em;
			box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
			@include transition(all 0.2s ease-in-out);
			transform: skewX(-35deg);*/

			@include transition(all 0.2s ease-in-out);
		}
	
	/*#mobilePhone{position: relative; z-index: 0;}*/
	
	.accountMenu, #logo {
		position: absolute;
	}
	
	.accountMenu {
		right: 2%;
		@include center(false, true);
	}
	
	#logo {
		left: 0%;
		/*top:0.25em;*/
		/*background-color: rgba(0,0,0,0.5);
		border-radius: 300px;
		box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
		padding: 3em;
		width: 135px;
		height: 125px;*/

		top:unset;
		bottom:0.25em;
	
	}
	
	.topMenu{
		
		margin: 0 auto;
		/*text-align: center;*/
		float: none;
		
		text-align:right;

	}

	/*#mainMenu {
		.nav { width:auto; }
		
		a.button {
			float: right;
			font-size: 1em;
				
			margin-top: .5em;
			margin-left:0.5em;
			@include transition(all 0.3s ease-in-out);
		}
	}

	.clearHeader {
		#mainMenu {
			a.button {display:none; padding: 0.5em 0.5em;}
		}
	}
	.darkHeader {
		#mainMenu {
			a.button {display:inline-block; padding: 1em 1em; }
		}
	}*/
	
}

/**************************
HERO - Home
***************************/



.nav {
       top: 120px;
}

/*********************
BLOCK LAYOUT
**********************/


/*#content #leftcol */.columnlayout {
	> div, li, article {
		width: 30%;

		&.staffVideo {
			width: 48%;
		}
	}
}


 .requestInfo {
    fieldset {
        width: 48%;
        margin-right: 1.5%;
        display: inline-block;
        vertical-align:top;

        &:nth-child(even){
            margin-right: 0;
        }

           
    }
}

#CTA {
	position: fixed;
	display: block;
	bottom: -430px;
	opacity: 0;
	width: 60%;
	background: transparent;
	margin: 0 auto;
	left: 45%;
	overflow: hidden;
	text-align: center;
	animation-delay: 0s;

	fieldset {
		width: 100%;
	}

	span.h2 {
		padding-left: 2em;
		@include transition(all 1s ease-in-out);
	}

	@include transition(all 1s ease-in-out);

	form {
		width: 80%;
		opacity: 0;
		@include transition(all 0.3s ease-in-out);
		transition-delay: 0;
		padding: 0;
		margin: 0em auto;
	}

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		/*background: $color-primary;*/
		box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
		position: absolute;
		top: 0;
		left: 0;
		z-index: -9999;
		@include transition(all 1s ease-in-out);
		transform: skew(-40deg);
	}

	&.expanded {
		background: transparent;
		left: 45%;
		width: 55%;
		bottom: 0;
		@include transition(all 1s ease-in-out);


		span.h2 {
			padding-left: 0;
			@include transition(all 1s ease-in-out);
		}

		.hbspt-form {
			background: $white;
			@include transition(all 2s ease-in-out);
		}
		
	}
}
#rightcol{
	margin: 2em 0;
	border: 1px solid darken($white, 10%);
	padding: 1em;
	background: darken($white, 4%);

	
 .requestInfo{
        fieldset {
            width: 100%;
            margin-right: 0;
            display: block;

        }
    }


}
  
/*********************
LOCATIONS STYLES
*********************/

/*Testimonials*/
.testimonial:not(.red-bg){
		width:97%;
		min-height: 160px;
		position: relative;
        margin-left:3%;

		.campusTestimonial{
			display: block;
            position: relative;
			width: 75%;
			text-align:center;
            margin: 0 auto;
		}

		.campusTestimonialAuthorImage{
			position: absolute;
			top: -25px;
			left: -50px;
		}
		.campusName {
		border:2px solid #2d357d;	
		}
		.campusTestimonial {
			font-size:1.2em;
		}
        .CampusTestimonialAuthor {display: block;
            position: relative;
			width:75%;
			text-align:right;
            margin: 0 auto;}

	}
	
	.testimonials {
		.row {	
			/*background:#ca2827;*/
		}
	}
	

.locations{

	
	
	.campusSchedule{
		border: 1px solid $color-tertiary;
		margin-bottom: 1em;
	
		th{display: table-cell; width: 20%; background: $color-tertiary; color: $white;}

		
		td{
			text-align: center;
			display: table-cell;
			width: auto;
			padding: 0.5em;
            

			&:first-child{
				border-top: none;
			}

			&:before{
				display: none;
				content: none;
			}

			&.campusScheduleCourseName:before{
				display: none;
				content: none;
				
			}

            &.campusScheduleLocation{
				font-size: 0.8em;

			}
			&.campusScheduleLocation:before{
				display: none;
				content: none;

			}
            &.campusScheduleType:before{
				display: none;
				content: none;

			}

			&.campusScheduleDays:before{
				display: none;
				content: none;

			}

			&.campusScheduleDate:before{
				display: none;
				content: none;
			}

            &.campusEnquiry {
                a.button{white-space: nowrap;}
            }

		}	
	}


.campusContactImage{display: block; margin: 0.5em auto; width: 200px; height: 200px; border-radius: 200px; border: 4px solid $white; box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.35);}
	.campusContact, #meetContact, #careerAdvisor{display: inline-block; margin: 0.5em 0.05em;}
	.campusContact{font-weight: 700;}
}	
      

	
	/*Jobs Board - Pulled across from old site*/

#jobs table thead { display: table-header-group; }
#jobs table td { display: table-cell; }




/*********************
NEWS LISTING
*********************/
   
.newsItem{
	display: inline-block;
	width: 49%;
	vertical-align: top;
	text-align: center;

	
  &:nth-child(even) #listing{
        	margin-right: 2%;
        }
} 


#body_519 #content #leftcol{
	
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;

	 > div{
		padding: 0;
		display: inline-block;
		width: 49%;
		
		flex: 1 1 auto;
		flex-grow: 0;
		margin-left: 1%;
		
		vertical-align: top;

		margin-bottom: 1em;

	

		#listing{ 
		height: 100%;

		.row{
			padding: 0;
		}
	

		}

		
    	&:nth-child(odd){
    		margin-right: 0;
    	}

	 }	
      
    
}
/*********************
GALLERY
*********************/



/* SLICK SLIDER */


.slick-slider { 
	
	.slick-list {
		
		.slick-track {
			
			.slick-slide {
				
				.slideshow_caption {
					
					.slideDescription {
						transform: translate3d(0,0,0);
						display: block;
					}
				}

			}
			
		}
	}


}	

	
/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}

ul.responsive3 {
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				.slideshow_caption {
                   /* border: 4px solid $white; */
                    /*background: rgba(255,255,255,0.8);*/
                    background: transparent;
                    text-align: center;
                    padding: 1em;
                    width: 60%;
                    
                     /* @include css-gradient(rgba(255,255,255,0.95), transparent);*/
                    /*transform: none;*/

                    .h1{
                        font-size: 4rem;
                        margin: 0;
                    }
					
					.secondary{
						display: inline-block;
						}

                    .h3, .slide_description{
                        display: block;
                    }

                    .h3{
                        font-size: 1.5rem;
                        margin: 0;
                    }
				}
			}
				
		}
			
	}
}

.slick-prev, .slick-next { top: 40%; }


   #confidenceTriggers{
       padding: 2em 0;
       line-height: 2;
       .block > .cf {
           display: flex;
		   flex-direction: row;
		   flex: 1 1 auto;
		   flex-wrap: wrap;


       .fourcol{
        width: 30.75%;
        vertical-align: middle;
        display: block;
		
		

        
     
        
    
      }
    }
      }


 /*************************
STICKY BACK TO TOP
*************************/
            .cd-top {
                height: 60px;
                width: 60px;
                right: 30px;
                bottom: 80px;
            }

#bottomBlocks{padding: 2em;}


/*********************
LANDING LAYOUT 2021
**********************/

body.landing-1, body.landing-2, body.fullwidth2, body.locations-2024 {


	#topBlocks {
        .custom-shape-divider-bottom-1619407762 {display:block;}
     }

    #topBlocks {
		display:block; visibility:visible;
		
    }
   
    #topBlocks .block {
        
        height: 55vh;

        .heading {
                width: 70%;
        }
        .heading h3 {visibility:visible; color:$white;}
         > p:first-of-type, picture, .heading > h1, .heading > p {display:block;}
		 > p:first-of-type, picture {width:100%; height: 100%;}
		 
    }

	#content #leftcol > div {
		padding: 1.5em 0;
	}

	.grey-bg {
       padding: 2em;
       margin-bottom: 2em;
    }

}

/*********************
LANDING LAYOUT 2021
**********************/


body.landing-1 {
	#leftcol {
		ul.siblinglinks {
			column-count: 7;

			li {
				position: relative;
				page-break-inside: avoid;
				break-inside: avoid-column;
			}
		}
	}
}

/*********************
LANDING LAYOUT 2024
**********************/

body.locations-2024 {

	//reset row paddings to 0
	    #content #leftcol > div:nth-of-type(2),
	    #content #leftcol > div:nth-of-type(2) > div,
        #content #leftcol > div#div1729 > div {
            padding-top: 0;
			padding-bottom: 0;
        }
	
	.columnlayout.grid-3 {
		> div:first-of-type {width: unset;}
		> div, li {
			flex:1;
			gap:1em;

			.card-image {
                    i {font-size:3em;}
                }

		}
		

	}

	/*HEADER*/
	/*#header {
		
		#mainMenu {
			.nav { width:auto; }
		
			a.button {
				float: right;
				font-size: 1em;
				
				margin-top: .5em;
				margin-left:0.5em;
				@include transition(all 0.3s ease-in-out);
			}
		}
		.clearHeader {
			#mainMenu {
				a.button {display:none; padding: 0.5em 0.5em;}
			}
		}
		.darkHeader {
			#mainMenu {
				a.button {display:inline-block; padding: 1em 1em; }
			}
		}
	}*/
	
	 //campus image in circle
        .campus-logo {
            
            img {
                top:-220px; 
                width:200px; height: 200px;
            }
        }


	 /*Course Schedule*/
        .campusSchedule {
            th {padding: 0.5em;}
            td {
                
                &:first-child { padding-top: 0.5em;}
                &:last-child { padding-bottom:0.5em;}
            }
        }

	/*GRIDS*/

	.grid-2 {
        > div, > li {grid-column: span 1;}
    }

	.image-stack, .article-stack {
	  display: grid;
	  position: relative; // imperative for the overlapping to work
	}

	.article-stack {
		grid-template-columns: repeat(12, 1fr);

		/*.article-stack__item img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}*/

		.article-stack__item--bottom {
			grid-column: 4 / span 12;
			grid-row: 1;
			.wrap-text {padding: 12%; padding-left: 35%;}
		}

		.article-stack__item--top {
			grid-row: 1;
			grid-column: 1 / span 5;
			margin-top: 10%; margin-bottom: 10%; // slightly arbitrary, keeps proportion once resized
			z-index: 1; // tells the browser to make this image on top
			padding:15%;
			box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
			-webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */ animation-delay: 0.5s;
		}
	}
}



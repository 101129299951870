/******************************************************************
Site Name:
Author:

Stylesheet: Mixins & Constants Stylesheet

This is where you can take advantage of Sass' great features:
Mixins & Constants. I won't go in-depth on how they work exactly,
there are a few articles below that will help do that. What I will
tell you is that this will help speed up simple changes like
changing a color or adding CSS3 techniques gradients.

A WORD OF WARNING: It's very easy to overdo it here. Be careful and
remember less is more.

******************************************************************/

/*********************
CLEARFIXIN'
*********************/

// Contain floats: nicolasgallagher.com/micro-clearfix-hack/
.cf {
  zoom: 1;
	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }
}

/*********************
TOOLS
*********************/

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}


/*********************
COLORS
Need help w/ choosing your colors? Try this site out:
http://0to255.com/
*********************/

$black: #0a0a0a;
$white: #ffffff;

$color-primary: $black; /* black */
$color-secondary: #ca2827; /* red */
$color-tertiary: $black; /*Grey*/
$color-quaternary: $black; 

$link-color:        $color-primary;
$link-hover:        $color-secondary;

$alert-yellow:      #ebe16f;
$alert-red:         #fbe3e4;
$alert-green:       #e6efc2;
$alert-blue:        #d5edf8;

/*
Here's a great tutorial on how to
use color variables properly:
http://sachagreif.com/sass-color-variables/
*/


/*********************
TYPOGRAPHY
*********************/

$sans-serif:        'aileron', sans-serif;
$serif:             serif;
$cursive:           cursive;
$headings:          sans-serif;


/* 	To embed your own fonts, use this syntax
	and place your fonts inside the
	library/fonts folder. For more information
	on embedding fonts, go to:
	http://www.fontsquirrel.com/
	Be sure to remove the comment brackets.
*/
/*	@font-face {
    	font-family: 'Font Name';
    	src: url('/library/fonts/font-name.eot');
    	src: url('/library/fonts/font-name.eot?#iefix') format('embedded-opentype'),
             url('/library/fonts/font-name.woff') format('woff'),
             url('/library/fonts/font-name.ttf') format('truetype'),
             url('/library/fonts/font-name.svg#font-name') format('svg');
    	font-weight: normal;
    	font-style: normal;
	}
*/
/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

// text alignment
.text-left   { text-align: left; }
.text-center { text-align: center; }
.text-right  { text-align: right; }


// alerts and notices
%alert {
	margin: 0 0;
	padding: 0.25em;
	border: 1px solid;
	font-size:0.70em;
	/*letter-spacing:1px;*/
}

.alert-help {
	@extend %alert;
	border-color: darken($alert-yellow, 5%);
	background: $alert-yellow;
}

.alert-info {
	@extend %alert;
	border-color: darken($alert-blue, 5%);
	background: $alert-blue;
}

.alert-error, .hs-error-msg, .hs_error_rollup {
	@extend %alert;
	border-color: darken($alert-red, 5%);
	background: $alert-red;
	color: darken($alert-red,40%);
	
}

.alert-success {
	@extend %alert;
	border-color: darken($alert-green, 5%);
	background: $alert-green;
}

/*********************
TRANSITION
*********************/

/*
I totally rewrote this to be cleaner and easier to use.
You'll need to be using Sass 3.2+ for these to work.
Thanks to @anthonyshort for the inspiration on these.
USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
	// defining prefixes so we can use them in mixins below
	$prefixes:      ("-webkit");
  @each $prefix in $prefixes {
    #{$prefix}-transition: $transition;
  }

  transition: $transition;
}

/*needed for when using scroll-down easing to anchor point on page*/

.scrollpoint {
    display: block;
    position: relative;
    top: -110px;
    visibility: hidden;
}

/*********************
Border Radius
*********************/
/*
USAGE: @include border-radius($small-border-radius);
*/

$small-border-radius:	0.25em;

@mixin border-radius($border-radius) {
	
  	border-radius: $small-border-radius;

}
/*
USAGE: @include border-radius($large-border-radius);
*/

$large-border-radius:	0.5em;

@mixin large-border-radius($large-border-radius) {
	
  	border-radius: $large-border-radius;

}


/*********************
Image zoom
*********************/
/*
USAGE: @include transform($zoom);
*/

$zoom:	scale(1.07);

@mixin transform($zoom) {
	// defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms", "-moz", "-o");
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: $zoom;
	}
	
  	transform: $zoom;
}

/*********************
Align Center
*********************/
/*
USAGE: 
@include center(); 
@include center(true, false);
@include center(false, true); 
*/

@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  // defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms");

  @if ($horizontal and $vertical) {
    top: 49.8%;
    left: 49.8%;
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: translate3d(-50.2%, -50.2%, 0);
        
	}
	 -ms-transform: translateX(-50%) translateY(-50%);/*correction for IE9*/
    transform: translate3d(-50.2%, -50.2%, 0);
    

  } @else if ($horizontal) {
    left: 50%;
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: translate3d(-50%, 0, 0);
        
	}
	-ms-transform: translateX(-50%) translateY(0%);/*correction*/
    transform: translate3d(-50%, 0, 0);

  } @else if ($vertical) {
    top: 50%;
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: translate3d(0, -50%, 0);
	}
	-ms-transform: translateX(0%) translateY(-50%);/*correction*/
    transform: translate3d(0, -50%, 0);
  }


}


/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: rgb(255, 255, 255), $to: rgba(255, 255, 255, 0) 70%) {
	background-color: $to;
	background-image: -webkit-linear-gradient(left, $from, $to);
	background-image: -moz-linear-gradient(left, $from, $to);
	background-image: -o-linear-gradient(left, $from, $to);
	background-image: linear-gradient(to right, $from, $to);
}


/*********************
BOX SIZING
*********************/

/* NOTE: value of "padding-box" is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)

/* @include box-sizing(border-box); */
@mixin box-sizing($type: border-box) {
	
	// defining prefixes so we can use them in mixins below
	$prefixes:      ("-webkit", "-moz", "-ms");
	@each $prefix in $prefixes {
		#{$prefix}-box-sizing: $type;
	 }

	box-sizing:         $type;
}

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing:    border-box;
	box-sizing:         border-box;
	
}

/********************************************
MIXIN CREATED FOR WHEN YOU NEED A BREAKPOINT
********************************************/
/*
USAGE: @include bp(baby-screen) {
    width: 100%;
  }
*/

@mixin bp($point) {
  
  $bp-babyscreen: "(min-width: 768px)";
  $bp-mamascreen: "(min-width: 1024px)";
  $bp-papascreen: "(min-width: 1240px)";

  @if $point == papa-screen {
    @media #{$bp-papascreen} { @content; }
  }
  @else if $point == mama-screen {
    @media #{$bp-mamascreen} { @content; }
  }
  @else if $point == baby-screen {
    @media #{$bp-babyscreen}  { @content; }
  }

}

/*********************
BUTTONS
*********************/
.button, button, input.button, input.hs-button {
    padding: 1em 1.5em;
    margin: 0.8em auto;
    text-transform: uppercase;
    font-family: $sans-serif;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid $black;
    background: transparent;
    color: $black;
	/*@include border-radius($small-border-radius);*/
	@include transition(all 0.15s ease-in-out);

    &:hover, &:focus, &:active {
        background: $black;
		border: 1px solid $black;
        color: $white;
		@include transition(all 0.15s ease-in-out);
    }

}

.button.primary, .hs-button.primary {
	background: $color-primary;
	border: 1px solid $color-primary;
    color: $white;
	&:hover, &:focus, &:active {
		background: darken($color-secondary, 10%);
		border: 1px solid darken($color-secondary, 10%);
        color: $white;
	}
}

.hbspt-form .hs-button.primary {
    width: 100%;
    width: -webkit-fill-available;
}

.button.secondary,
body.landing-1 #content .requestInfo input.button,
body.landing-2 #content .requestInfo input.button  {
	border: 1px solid $color-secondary;
	background: $color-secondary;
    color: $white;
	&:hover, &:focus, &:active {
		background: darken($color-secondary, 10%);
		border: 1px solid darken($color-secondary, 10%);
        color: $white;
	}
}

.requestInfo .button, input.hs-button {
	border: 1px solid $white;
	background: $white;
    color: $black;
	&:hover, &:focus, &:active {
		background: $black;
		border: 1px solid $black;
        color: $white;
	}
}

.button.tertiary, .responsive3 .button:not(.secondary) {
	border: 1px solid $white;
	background: $white;
    color: $black;
	&:hover, &:focus, &:active {
		background: $black;
		border: 1px solid $black;
        color: $white;
	}
}

.campusEnquiry .button.secondary {
	border: 1px solid $black;
	background: transparent;
    color:$black;
	&:hover, &:focus, &:active {
		background: $black;
		border: 1px solid $black;
        color:$white;
	}
}

.buttonlinks, buttonlinks {
	padding: 1em 1.5em;
	margin: 0.8em auto;
	text-transform: uppercase;
	font-family: $sans-serif;
	display: inline-block;
	text-decoration: none;
	cursor: default;
	border: 1px solid $black;
	background: transparent;
	color: $black;
	/*@include border-radius($small-border-radius);*/
	@include transition(all 0.15s ease-in-out);

	&:hover, &:focus, &:active {
		color: $black;
	}
}

	/*ugly file input*/

	.upload {
		cursor: pointer;
	}


	.ones {
		animation-delay: 1s;
	}

	.onefives {
		animation-delay: 1.5s;
	}

	.twos {
		animation-delay: 2s;
	}

	.twofives {
		animation-delay: 2.5s;
	}








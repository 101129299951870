/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/
.hideonDesktop {display:none;}
.button.hideonMobile {display: inline-block;}
.topLine.hideonMobile {display: block;}

#header {	
	.student-login {display:none;}
	min-height: 0;
	#mobilePhone, .topLine {text-align: right; padding-right: 2em;} 

	.topLine {
		
		background: $white;
		padding: 0.5em 0; 
		text-align: right;
	
		padding-right: 0;

		.supplementaryLinks > ul > li {

			padding: 0.5em;
		
			/*&:first-child{
				display: none;
			}*/
		
			&:last-child{
				position: relative;
				width: 265px;
				font-size: 1.3rem;
				text-align: center;
				display: inline-block;
			}


			&:last-child:after, &:nth-last-child(2):after{
				content: none; 
			}

			a.enrol {
				margin-left: 1em;
				z-index: 999;
				position: relative;
				font-weight: 700;
			}

		}
	}

	#mainMenu {
			position:absolute;
			bottom:1em;
			right:0;
			text-align:right;
			width:85%;

			@include transition(all 0.3s ease-in-out);

			a.button, .nav {vertical-align:middle;}

			a.button {
				float: right;
				font-size: 1em;
				margin-top: .5em;
				margin-left:0.5em;
				@include transition(all 0.3s ease-in-out);
			}

			.nav { 
				position: relative;
				bottom:unset;
				top:unset;
				display:inline-block;
				width:auto;
				@include transition(all 0.3s ease-in-out);
			}
		}


	.menu {
		top: 0px;
		min-height: 0;
	}

	/*.row{
		padding-right: 3em;
	}*/
	
	.clearHeader {
		@include transition(all 0.3s ease-in-out);

		.row {height:unset; min-height:100px;}

		a.button {display:none; padding: 0.5em 0.5em; @include transition(all 0.3s ease-in-out);}
		
		/*.row, #mainMenu .nav > li {min-height:90px; @include transition(all 0.2s ease-in-out);}*/
		#mainMenu .nav > li { padding: 1em 0.45em; @include transition(all 0.3s ease-in-out);}

        /*#mainMenu .nav > li > a { color:$black;}*/
		
		position: relative;
		background-color: $color-secondary;

		/*@include css-gradient(darken($color-secondary, 8%),  $color-secondary);*/
		@include transition(all 0.3s ease-in-out);
		#logo {
			@include transition(all 0.3s ease-in-out);
			width: 180px;
			height: 150px;
			background-size: 180px 150px;
			/*top: -2.5em;*/
			bottom:-1.5em;
			visibility: visible;
			opacity: 1;
			border-radius: 0;
			box-shadow: none;
			padding: none;
		}

		#logo2 {
			/*@include transition(all 0.2s ease-in-out);
			display: block;
			opacity: 0;
			visibility: hidden;*/
		}
		#mainMenu {
			@include transition(all 0.3s ease-in-out);
		}

		#logoHelper{
			display: block;			
			width: 500px;
			height: 280px;
			background: $color-primary;
			position: absolute;
			top: -130px;
			margin-left: 0;
			left: -210px;
			box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
			@include transition(all 0.3s ease-in-out);
			transform: skewX(-35deg);
		}
		
	}
	
	.darkHeader {
		@include transition(all 0.3s ease-in-out);

		#mainMenu {
			a.button {display:inline-block; padding: 1em 1em; margin-top:1.75em;}
		}


		/*.row, #mainMenu .nav > li {min-height:80px; @include transition(all 0.2s ease-in-out);}*/

		.row {height: 130px;}
		
		
		position: fixed;
		background-color: $black;
		box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8); 
		
		@include transition(all 0.3s ease-in-out);

		#mainMenu .nav > li { padding: 1em 0em; @include transition(all 0.3s ease-in-out);}
		#logo{
			/*opacity: 0;
			visibility: hidden;*/

			width: 132px;
			height: 110px;
			background-size: 132px 110px;
			@include transition(all 0.3s ease-in-out);
		}
		/*#logo2 {
			@include transition(all 0.2s ease-in-out);
			display: block;
			position: absolute;
			width: 96px;
			height: 80px;
			background-size: 96px 80px;
			top: 5px;
			background: transparent url('../images/logo.svg') no-repeat center top;
			visibility: visible;
			opacity: 1;
		}*/
		#mainMenu {
			@include transition(all 0.3s ease-in-out);
		}
		
		/*&:hover {
			.row, #mainMenu .nav > li {min-height:90px; @include transition(all 0.2s ease-in-out);}
			#mainMenu .nav > li { padding: 2em 0.4em;@include transition(all 0.2s ease-in-out);}
			#logo2 {top: 1em;@include transition(all 0.2s ease-in-out);}
			#mainMenu a.button {margin-top: 1.5em;}
		}*/

        /*.topMenu .nav > li > a:after, .topMenu .nav > li > a.parent:after {}*/
	}
	
}


/*********************
NAVIGATION STYLES
*********************/
.topLine{
	text-transform: uppercase;
	overflow: hidden;   
	position: relative;

	.supplementaryLinks > ul > li{
		display: inline-block;
		padding: 0;
		margin: 0;
		font-size: 1.1em;
		line-height: 1.3;

		a{
			margin: 0;
			padding: 0 0.3em; 
			padding-bottom: 0;
		}

		/*&:after{
			content: '|';
			padding: 0 0.2em 0 0.5em  ;
			display: inline-block;
			margin-top: -0.5em;
			position: relative;
			top: 0;
		} */

		&:last-child{
			display: none;
		}


		&:last-child:after, &:nth-last-child(2):after{
			content: none; 
		}

	

	}	
	
}


.topMenu {

    a.toggleMenu {
        display: none;
    }

    .nav {
        /*position: relative;
        width: 80%;
        border: 0;
        text-align: right;
        margin-top: 0;
        float: right;*/

		position:absolute;
		bottom:1em;
		right:0;
   

        &.phone{
        	display: inline;
        }
		> li:nth-child(5){
				> ul{
					display: none!important;
				}
			}

        > li {
            display: inline-block;
            margin: 0em 0.6em;
			/*min-height: 120px;*/

           > a, > a.parent {
                 position: relative;

                 &:after {
					content: '';
					position: absolute;
					display: block;
					width: 0;
					height: 2px;
					left: 0;
					bottom: 0;
					right: auto;
					width: 0;
					z-index: 999;
					background-color: $white;
					@include transition(all 0.3s ease-in-out 0s);
				}

				&:hover:after, &.activerootmenulink:after  {
					right: 0;
					left: auto;
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					
					height: 2px;
					@include transition(all 0.3s ease-in-out 0s);
				}

				
				
            }
            /*hide home link to public*/
            &:first-child {
                display: none;
            }
			/*hide study link to public*/
           /* &:nth-child(6) {
                display: none;
            }*/
			/*hide choose a career to public - kept as backup*/
            &:last-child {
                display: none;
            }
			
			> ul.submenu {
                opacity: 0;
                top:50%;
               
            /*
			Animation effect for top nav
			*/
				/*@include transition(top,opacity,visibility 0.2s linear);*/
                /*transition: 0.2s linear;
                transition-property: top,opacity,visibility;
				@include transition(all 0.2s linear);*/
            }
			

        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-tertiary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;
				visibility: hidden;
				
				box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
				

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0; 
                        margin-right: 0;
						background: $color-tertiary;
						@include transition(all 0.3s ease-in-out 0s);

                        &:hover,
                        &:focus {
                            border-top: none;
                            background: $color-primary;
                            @include transition(all 0.3s ease-in-out 0s);

                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
			
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav li.hover > ul { left: 0; visibility: visible;  }
.nav > li.hover > ul.submenu { opacity:1; top:100%; }
.nav li li ul { left: -9999px; z-index: 9999; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 9999; position:absolute; }

/* active state on home - not sure where else to put this for now*/



/*********************
MEGA MENU
*********************/

#header {
    .megaMenu {
        display: none;
        position: absolute;
        width: 100%;
        background: $black;

        .row {
            padding: 2em;

            > ul {
                padding: 0;
                column-count: 4;
				column-gap: 1em;
                width: 100%;
                float: left;

                > li {
                    margin-bottom: 1em;
                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;

                    a {
                        color: $white;
                        line-height: 1.4;
						padding: 0.4em 0;
						display:block;
						opacity: .8;
						&:hover {opacity: 1;}
                    }

                    > a {
                        text-transform: uppercase;
                        letter-spacing: .08em;
                        font-weight: 600;
                        opacity: 1;
                    }

                    > ul {
						padding-left:0;
						> li {
							margin-bottom:0.5em;
							position:relative;
							/*padding-left: 2em;
							margin-bottom: 1em;

							&:before {
								content: "\f054";
								font-family: "Font Awesome 5 Pro";
								font-weight: 300;
								color: #0a0a0a;
								position: absolute;
								display: block;
								top: 0;
								left: 0;
								font-size: 1.5em;
								line-height: 1.1;
							}*/

						}
                    }
                }
            }

            div {
                float: left;
                width: 25%;

                ul {
                    padding: 0;

                    li {
                        margin-bottom: 1em;

                        a {
                            color: $white;
                            line-height: 2.5;
                        }
                    }
                }
            }
        }
    }
}



#content {height:auto;}

.slick-prev, .slick-next { top: 50%; }
.slick-next { right: 50px; }
.slick-prev { left: 50px; }


/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet

Here's where you can start getting into the good stuff.
This size will work on iPads, other tablets, and desktops.
So you can start working with more styles, background images,
and other resources. You'll also notice the grid starts to
come into play. Have fun!

******************************************************************/
/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/



/*********************
GENERAL STYLES
*********************/

body {}



/*********************
LAYOUT & GRID STYLES
*********************/

.row, .row-small, .row-x-small, 
/*body:not(.locations) #leftcol > div > div,*/
body:not(.locations-old) #content #leftcol > div > div,
body.locations-2024 #content #leftcol > div > div
{
	width: 95%;
}

.row, 
/*body:not(.locations) #leftcol > div > div,*/
body:not(.locations-old) #content #leftcol > div > div,
body.locations-2024 #content #leftcol > div > div
 {
	max-width: 1600px;
}

body.Home #content #leftcol > div > div {width:100%; max-width: none;}

#header .row, #hero .row {
	max-width: 1650px;
}

.row-small{
	max-width: 1000px;
}

.row-x-small{
	max-width: 780px;
}

/*********************
HEADER STYLES
*********************/




/***********************
HERO - INNER PAGES
************************/
#hero {
	.topBlock {

		h1,.h1 {
			padding: .5em 1em;
			width: auto;
			bottom: inherit;
			@include center(); 
		}
	}
}

/*********************
NAVIGATION STYLES
*********************/


.menuWrap {
	width: 95%;
}
.topMenu {	
	text-align: right;
	
}
	


/*********************
CONTENT STYLES
*********************/

body.Home #leftcol {
	.threecol{
		width: 50%; 
		margin: 0;
		margin-left: 0;

		&.last{
			float: left;
		}
	}

	.fourcol{
		width: 50%;
		margin: 0;
		margin-left: 0;
			&.last{
			float: left;
		}
		&:nth-child(3){
			width: 100%;
		}
		}
}

/* content area all inner pages */
#content {
	.row {
	}
}
	

	
	

blockquote {
	&:before {
		/*margin-bottom:5em;*/
		float: left;
		font-size: 2em;
	}
}


.icons, .courseOptions{
	.fourcol{
	width: 33.33%;
	margin-left: auto;
	display: inline-block;
	&:nth-child(even){
		margin-left: auto;
		}
	}

	 .threecol{
	width: 25%;
	margin-left: auto;
	display: inline-block;
	&:nth-child(even){
		margin-left: auto;
		}
	}

}	


/*********************
HOME STYLES
*********************/



/*********************
LANDING PAGE STYLES
**********************/

body.Landing {
	#content {
		#leftcol {
			> div {
				padding: 4em 0;
			}
		}
	}
}


/*********************
BLOCK LAYOUT
**********************/	

.columnlayout {
	> div, article {
		display:-moz-inline-stack; 
		display:inline-block;
		vertical-align: top;
			div {
				width: auto;
			
			}
	}
}

/*********************************
TOP BLOCKS
*********************************/



/*********************************
BOTTOM BLOCKS
*********************************/
#bottomblocks {
	padding: 1em;

	> div {
		padding: 2em;
	}

	.parallax-window {
		padding: 3em 0;
		.borderWrap {
			width: 60%;
		}
	}
}
.USPs {
    text-align: left;
    

    .threecol{
    
        float: none;
        padding: 1em;
        display: inline-block;
    }
    .ninecol{
        display: inline-block;
        float: none;
    }
}
/*********************
RIGHT BLOCKS
*********************/

#rightcol {
	/*margin-top: 0;*/
}


#confidenceTriggers {
    .block > .cf {
        .fourcol {
            .threecol {
				left: 45px;
            }

            .ninecol {
				padding-left: 1em;

                > p {
                    width: 80%;
                    margin: 0 auto;
                }
            }
        }
    }
}

/*#CTA {

	#slideoutform {*/
		.hbspt-form {
			.hs_recaptcha{ }
			
		}
/*	}

}*/

/*********************
NEWS LISTING
*********************/
	
div.listingImg { 
	
	float:left; 
	width:30%; 
		
}
	
div.listingTxt { 
	
	float:left; 
	width:70%;
		
}
	
div.caseStudy {
	
	float:left; 
	width: 48%; 
	padding: 1em 0 2em 0; 
	position: relative; 
	margin-right:1.9%
	
}

/*********************
GALLERY
*********************/

#gallery {
	
		li {
		
			width: 22.5%;
			margin-right: 2%;
		
		}
	
}

/*********************
VIDEOS
*********************/

.videoWrapper { 

	margin-left: 0px;
	margin-right: 0px;
	
}



/*********************
FOOTER STYLES
*********************/
#confidence {
		background: black;
}
#address {
	form { 
		margin: 0 0;
	}
}
#footer {
    
	> div {
		
		#copyright {
			.first {
				a {
					display: inline;
				}
			}
		}
        
		#privacy {
			text-align: right;
		}
		#facebook {
			text-align: center;
		}
		
	}
    > div#secondfooter {text-align: center;}
}

/*
you'll probably need to do quite a bit
of overriding here if you styled them for
mobile. Make sure to double check these!
*/


/*SLIDER*/
/* Bug fix for flashing on Slick slideshow */

/*Full width slider */
ul.responsive3 {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				/*max-height: 50vh;*/
				overflow:hidden;
				
				.row {width:95%;}
				
			
				img {
				width: 100%;
					min-width: 100%;
					height: auto;
         
					max-height: none;
				}
				
				 .slideshow_caption {
                    -webkit-backface-visibility: hidden;
                  
					
                }
			}
		}
	}
}

.locationsMapsContainer {
    height: 455px; display:block; width:100%;
}

/*********************
LANDING LAYOUT 2021
**********************/

body.landing-1, body.landing-2, body.locations-2024 {
	#leftcol {
		ul.siblinglinks {
			column-count: 3;
			li {
				position: relative;
				page-break-inside: avoid;        
				break-inside: avoid-column;
				
			}
		}
	}

	.locationsMapsContainer {
		height: 500px; 
	}
}



/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/



.hideonDesktop {display: none;}

/*LANDING 2021 */

body.landing-1, body.landing-2, body.locations-2024 {
    /*font-size:110%;*/
	.button, button, input.button, input.hs-button {font-size:100%;}
	.button.secondary {font-size:110%;}
	.testimonial-2 .article-stack__item--top i { font-size: 4em;}
	h1, .h1  { font-size: 2.5em;}
    h2, .h2 { font-size: 2em;}
    h3, .h3 { font-size: 1.2em;}
	button, .button, h4, .h4 { font-size:1.1em; }
	#confidenceTriggers {font-size: 0.8em;}
    h1, .h1, h2, .h2, h3, .h3, .card-item h3, h4, .h4 { margin: 0.5em 0;}
	h3, .h3{  margin-bottom: 0.5em!important;}
	#topBlocks {
        h1, .h1 { font-size: 3.5em;}
        
    }
	.bigger, .campusTestimonial, #meetContact {font-size:1.4em;}

}

body.fullwidth2 {
	#topBlocks {
		h1, .h1 {
			font-size: 3.5em;
		}
	}
}

/*********************
HEADER STYLES
*********************/
/***********************
HERO - INNER PAGES
************************/
body:not(.Home) #hero {
	max-height: 250px;
	overflow: hidden;
}

	


/*********************
NAVIGATION STYLES
*********************/

.breadWrap #breadcrumbs{
		&:before	{
			width: 75%;

			@include bp(papa-screen) {

				width: 60%;
			}


	}
}	
#header .clearHeader #logoHelper{left: -120px;}
#header .topLine{
	
	
}

#header .clearHeader, #header .darkHeader{
#mainMenu {		
	.nav {		
		> li {
			padding-left: 0.6em;
			padding-right: 0.6em;
		}
	}
}
}
/*
#header .clearHeader #logo{
	top: -2.5em;
	}
*/

/*********************
CONTENT STYLES
*********************/

/* content area all inner pages */
#content {
		
		hr {
			margin: 4em 0;
		}
		
}


/*********************
BLOCK LAYOUT
**********************/	
body#body_388 {

	.columnlayout {	
		> div, li, article { width: 20%;}
	}
}

body#body_640 {

	#content #leftcol .columnlayout {
		> div, li, article {
			width: 100%;
		}
	}
}

/*#content #leftcol */.columnlayout {
	> div, li, article {
			width: 19%;
	}
}


/******************************************************************
FORM STYLES
******************************************************************/
.respond-form, .requestInfo, .hbspt-form {
	form {
		
			/*.hs_recaptcha, .hs_submit {display:inline-block; width:50%; float:left;text-align:left;}
			.hs_submit {
					text-align:right;
					div.actions {text-align:right; margin-right:8px;}
			}*/
		
	}
}
/*********************************
BOTTOM BLOCKS
*********************************/
#bottomBlocks{padding: 3em;}


/*Full width slider */
 ul.responsive3.testimonials{
		.testimonialText{width: 70%;}
	.slick-list .slick-track .slick-slide 	img.campusContactImage{
			margin: 0 auto;
			display: block;
		}
	
 }

ul.responsive3 {
	.slick-list {
			
		.slick-track {
				
			.slick-slide {
				/*.slideshow_caption {  width: auto; padding-left: 2em; padding: 2em; top:20%; }*/
			}
				
		}
			
	}
}

#CTA { 
	
		
		width: 50%;
		left: 60%;
        
		&.expanded{
			

        	left: 50%;
			width: 50%;
         
            
        }

       

     

	}
	

/*********************
LOCATIONS STYLES
*********************/

/*Testimonials*/
.testimonial:not(.red-bg){

		.campusTestimonial{
			padding-left: 8em;
		}
}

/*********************
LANDING LAYOUT 2021
**********************/

body.landing-1, body.landing-2, body.fullwidth2, body.locations-2024 {

	#topBlocks .block {
		background: $black;
		height: 70vh;

		.heading {
			@include center(false, true);
			width: 45%;
			left: 5%;
			top: 45%;
			text-align: left;

			@include bp(papa-screen) {
				width: 50%;
			}
		}
	}

	#content #leftcol > div:first-child {
		padding-top: 1em;
	}

	#content #leftcol > div {
		padding: 2em 0;

		&:first-child{ padding-top: 1em; }
	}

	.grey-bg {
		margin-top: -180px;
	}
	/*align campus images used on template 2 - on Start a new career*/
	img.img-aligner, h3 > img.gymImage {
		float: right;
		margin-top: -100px;
	}
	/*Icons + text lists*/
	.wrapIcons.grid-1, .wrapIcons.grid-2 {

		> div {

			.card-image, .card-item {
			}

			.card-image {
				width: 12%;
			}

			.card-item {
				width: 85%;
			}
		}
	}

	.wrapIcons.grid-2 {

		> div {
			width: 31%;
			flex-direction: column;

			.card-image, .card-item {
				width: 100%;
				text-align: center;
			}

			.card-image {

				img {
					max-width: 100px;
					margin: 0 auto;
				}
			}
		}
	}
}

/*********************
LANDING LAYOUT 2024
**********************/

body.locations-2024 {

	.grey-bg {
		margin-top: 0;
	}

	.flexWrap, .sixcol {
         .wrap {padding: 3em 0;}
     }
}

/* DIVIDERS*/

.custom-shape-divider-top-1619406898,
.custom-shape-divider-bottom-1619407762{

     svg {
       
        height: 125px;
    }
}